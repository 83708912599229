/* eslint-disable no-restricted-imports */
import { useTranslation } from 'react-i18next';

import IconArrowDown from '@/componentes/Icon/IconArrowDown';
import { CarbonDocument } from '@/features/documents';
import { FormMap } from '@/features/documents/components/Map';

type ProjectInfoProps = {
  project: CarbonDocument;
};

const ProjectInfo = ({ project }: ProjectInfoProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mt-10 mb-4 flex items-center justify-between">
        <h4 className="text-2xl font-semibold">{t('projectDetail.projectInfo.title')}</h4>
        <IconArrowDown />
      </div>
      <hr />
      <div className="flex gap-5 pt-5">
        <div className="flex w-1/2 flex-col justify-center gap-5">
          <FormMap lat={Number(project.project_latitude)} lng={Number(project.project_longitude)} />
        </div>
        <div className="w-1/2">
          {/*<div className="flex justify-between py-3">*/}
          {/*  <p className="text-neutral-4">{t('projectDetail.projectInfo.company')}</p>*/}
          {/*  <p className="font-medium">*/}
          {/*    {' '}*/}
          {/*    <span className="text-red-500">TODO</span>*/}
          {/*  </p>*/}
          {/*</div>*/}
          {/*<hr />*/}
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.projectInfo.hostCountry')}</p>
            <p className="font-medium">{project.country?.name}</p>
          </div>
          <hr />
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.projectInfo.web')}</p>
            <a
              href={project.project_url}
              target="_blank"
              rel="noreferrer"
              className="text-primary-blue font-medium underline"
            >
              {' '}
              {t('projectDetail.projectInfo.pojectWebsite')}
            </a>
          </div>
          <hr />
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.projectInfo.address')}</p>
            <p className="font-medium">{`${project.nft.owner_address.substring(0, 14)}...`}</p>
          </div>
          <hr />
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.projectInfo.type')}</p>
            <p className="font-medium">{project.project_type?.name}</p>
          </div>
          <hr />
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.projectInfo.video')}</p>
            <a
              href={project.project_video}
              target="_blank"
              rel="noreferrer"
              className="text-primary-blue font-medium underline"
            >
              {t('projectDetail.projectInfo.projectVideo')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfo;
