type IconArrowDownProps = {
  stroke?: string;
};

const IconArrowDown = ({ stroke }: IconArrowDownProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m3 6 5 5 5-5"
        stroke={stroke ? stroke : '#021120'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconArrowDown;
