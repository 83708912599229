import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@/componentes/Icon/Icon';

type WarningMessageProps = {
  message: string;
  linkMessage?: string;
};

export const WarningMessage = ({ message, linkMessage }: WarningMessageProps) => {
  const [isOpen, setIsOpen] = useState(true);

  if (isOpen && message) {
    return (
      <div className="flex w-full justify-between bg-[#cf4747] py-1 px-8 text-white">
        <div className="flex items-center">
          <Icon id="warning" className="mr-3 h-5 w-5" />
          <span>{message}</span>
          {linkMessage && (
            <Link to="/profile">
              <span className="ml-2 underline underline-offset-4">{linkMessage}</span>
            </Link>
          )}
        </div>
        <button
          className="p-1"
          onClick={() => {
            setIsOpen(!isOpen);
            localStorage.setItem('warningMessage', 'true');
          }}
        >
          <Icon id="x-close-white" className="h-8 w-8 rounded-full" />
        </button>
      </div>
    );
  } else {
    return <></>;
  }
};
