// import React, { createContext, useContext, useState } from 'react';
import { createContext, ReactElement, useContext, useState } from 'react';

interface OtherState {
  defaultFifoProject: string;
  setFifoProject: (project: string) => void;
}

interface ProviderProps {
  children: ReactElement;
}

const defaultOtherState: OtherState = {
  defaultFifoProject: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFifoProject: () => { },
};

const OtherContext = createContext<OtherState>(defaultOtherState);

// export const useOtherContext = () => useContext(OtherContext);

export const OtherContextProvider = ({ children }: ProviderProps) => {
  const [defaultFifoProject, setDefaultFifoProject] = useState("");

  const setFifoProject = (project: string) => {
    // console.log('guardo el default fifo project: ' + project)
    setDefaultFifoProject(project);

  }

  const value = {
    defaultFifoProject,
    setFifoProject,
  };

  return (<OtherContext.Provider value={value}>{children}</OtherContext.Provider>);
  // return (<OtherContext.Provider value={value}>{children}</OtherContext.Provider>);
};

export const useOtherContext = () => {
  const ctx = useContext(OtherContext);
  if (!ctx) {
    throw new Error('usePaginationContext must be used within a PaginationContextProvider');
  }

  const { defaultFifoProject, setFifoProject } = ctx;

  return { defaultFifoProject, setFifoProject };
};