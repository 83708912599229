import i18next from 'i18next';
export const sdgList = [
  {
    id: 1,
    code: 1,
    name: i18next.t('sdgs.sdg1.name'),
    description: i18next.t('sdgs.sdg1.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-01.png',
  },
  {
    id: 2,
    code: 2,
    name: i18next.t('sdgs.sdg2.name'),
    description: i18next.t('sdgs.sdg2.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-02.png',
  },
  {
    id: 3,
    code: 3,
    name: i18next.t('sdgs.sdg3.name'),
    description: i18next.t('sdgs.sdg3.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-03.png',
  },
  {
    id: 4,
    code: 4,
    name: i18next.t('sdgs.sdg4.name'),
    description: i18next.t('sdgs.sdg4.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-04.png',
  },
  {
    id: 5,
    code: 5,
    name: i18next.t('sdgs.sdg5.name'),
    description: i18next.t('sdgs.sdg5.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-05.png',
  },
  {
    id: 6,
    code: 6,
    name: i18next.t('sdgs.sdg6.name'),
    description: i18next.t('sdgs.sdg6.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-06.png',
  },
  {
    id: 7,
    code: 7,
    name: i18next.t('sdgs.sdg7.name'),
    description: i18next.t('sdgs.sdg7.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-07.png',
  },
  {
    id: 8,
    code: 8,
    name: i18next.t('sdgs.sdg8.name'),
    description: i18next.t('sdgs.sdg8.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-08.png',
  },
  {
    id: 9,
    code: 9,
    name: i18next.t('sdgs.sdg9.name'),
    description: i18next.t('sdgs.sdg9.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-09.png',
  },
  {
    id: 10,
    code: 10,
    name: i18next.t('sdgs.sdg10.name'),
    description: i18next.t('sdgs.sdg10.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-10.png',
  },
  {
    id: 11,
    code: 11,
    name: i18next.t('sdgs.sdg11.name'),
    description: i18next.t('sdgs.sdg11.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-11.png',
  },
  {
    id: 12,
    code: 12,
    name: i18next.t('sdgs.sdg12.name'),
    description: i18next.t('sdgs.sdg12.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-12.png',
  },
  {
    id: 13,
    code: 13,
    name: i18next.t('sdgs.sdg13.name'),
    description: i18next.t('sdgs.sdg13.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-13.png',
  },
  {
    id: 14,
    code: 14,
    name: i18next.t('sdgs.sdg14.name'),
    description: i18next.t('sdgs.sdg14.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-14.png',
  },
  {
    id: 15,
    code: 15,
    name: i18next.t('sdgs.sdg15.name'),
    description: i18next.t('sdgs.sdg15.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-15.png',
  },
  {
    id: 16,
    code: 16,
    name: i18next.t('sdgs.sdg16.name'),
    description: i18next.t('sdgs.sdg16.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-16.png',
  },
  {
    id: 17,
    code: 17,
    name: i18next.t('sdgs.sdg17.name'),
    description: i18next.t('sdgs.sdg17.description'),
    icon: '/icons/iconsSdg/E-WEB-Goal-17.png',
  },
];
