/* eslint-disable no-restricted-imports */
import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import { GeneralStats } from '@/features/generalStats/GeneralStats';
import { AboutUs, EmailVerificationCallback, TermsAndConditions } from '@/features/misc';
import { NFTRouter } from '@/features/nfts';
import { Projects } from '@/features/projects/Projects';
import ProjectDetail from '@/features/projects/projectsDetail/ProjectDetail';
import { Tokenize } from '@/features/tokenize/Tokenize';
import { useAuth } from '@/lib/auth';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRouter = () => {
  const auth = useAuth();

  const commonRoutes = [
    {
      path: '/',
      element: <Outlet />,
      children: [
        { path: '/about', element: <AboutUs /> },
        { path: '/general-stats', element: <GeneralStats /> },
        { path: '/projects', element: <Projects /> },
        { path: '/tokenize', element: <Tokenize /> },
        { path: '/projects/project/:id', element: <ProjectDetail /> },
        { path: '/terms-conditions', element: <TermsAndConditions /> },
        { path: '/verify-email', element: <EmailVerificationCallback /> },
        { path: '*', element: <Navigate to="/" /> },
      ],
    },
    { path: '/nfts/*', element: <NFTRouter /> },
  ];

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
