import { useTranslation } from 'react-i18next';

import IconArrowDown from '@/componentes/Icon/IconArrowDown';
import { CarbonDocument } from '@/features/documents';

import { formatDate } from '..';

type ProjectCertificationProps = {
  project: CarbonDocument;
};

const ProjectCertification = ({ project }: ProjectCertificationProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="mt-10 mb-4 flex items-center justify-between">
        <h4 className="text-2xl font-semibold">{t('projectDetail.certification.title')}</h4>
        <IconArrowDown />
      </div>
      <hr />
      <div className="flex gap-4 pt-5">
        <div className="w-1/2">
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.certification.validator')}</p>
            <p className="font-medium">{project.validator?.name}</p>
          </div>
          <hr />
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.certification.firstVerifier')}</p>
            <p className="font-medium">{project.first_verifier?.name} </p>
          </div>
          <hr />
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.certification.creditStart')}</p>
            <p className="font-medium">{formatDate(project.credit_start)}</p>
          </div>
          <hr />
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">
              {t('projectDetail.certification.validationDocumentation')}
            </p>
            <a
              href={project.validation_report?.url}
              target="_blank"
              rel="noreferrer"
              className="text-primary-blue font-medium underline"
            >
              {t('projectDetail.certification.projectPdf')}
            </a>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.certification.status')}</p>
            <p className="font-medium">{project.status}</p>
          </div>
          <hr />
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.certification.type')}</p>
            <p className="font-medium">{project.type?.name} </p>
          </div>
          <hr />
          <div className="flex justify-between py-3">
            <p className="text-neutral-4">{t('projectDetail.certification.creditEnd')}</p>
            <p className="font-medium">{formatDate(project.credit_end)}</p>
          </div>
          <hr />
          <div className="flex justify-between gap-5 py-3">
            <p className="text-neutral-4">{t('projectDetail.certification.standards')}</p>
            <p className="max-w-[150px] font-medium">{project.standard?.name} </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCertification;
