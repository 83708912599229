import { BasicDataType } from '@/features/documents';
import { Metadata, Nft } from '@/features/nfts';

export const compensationKeys = {
  all: ['compensations'] as const,
  lists: () => [...compensationKeys.all, 'list'] as const,
  me: () => [...compensationKeys.all, 'list', 'me'] as const,
  detail: (id: string) => [...compensationKeys.all, 'detail', id] as const,
  paginated: (filter: Record<string, unknown>) =>
    [...compensationKeys.all, 'list', 'paginated', JSON.stringify(filter)] as const,
};

export interface CompensationCalculation {
  amount: number;
  fee: number;
  assets: number[]; // blockchain asa ids
  pickedNfts: PickedNft[];
  encodedTransferTxn: { [key: string]: number };
  encodedFundsTxn: { [key: string]: number };
  encodedBurnTxn: { [key: string]: number };
  signature: { [key: string]: number };
}

export interface Standard {
  name: string;
  id: string;
}

export interface PickedNftCarbonDocument {
  title: string;
  project_url: string;
  description: string;
  thumbnail: Thumbnail;
  credit_start: string;
  credit_end: string;
  country: BasicDataType;
  sdgs: BasicDataType[];
  project_type: BasicDataType;
  standard: Standard;
  serial_number: string;
  id: string;
  credits: string;
}

export interface Thumbnail {
  createdAt: string;
  ext: string;
  formats: any;
  hash: string;
  height: number;
  id: string;
  mime: string;
  name: string;
  provider: string;
  related: string[];
  size: number;
  updatedAt: string;
  url: string;
  width: number;
  __v: number;
  _id: string;
}

export interface PickedNft {
  id: string;
  metadata: Metadata;
  asa_id: string;
  supply: string;
  supply_remaining: string;
  carbon_document: PickedNftCarbonDocument;
  burnWillTimeoutOn: number;
  supply_burn_amount: number;
}

export interface Compensation {
  consolidation_certificate_ipfs_cid?: string;
  state: string;
  nfts: Nft[];
  burn_receipt: Record<string, any>;
  compensation_receipt_nft?: Nft;
  compensation_nft?: Nft;
  _id: string;
  txn_id: string;
  amount: string;
  published_at: Date;
  createdAt: Date;
  updatedAt: Date;
  receipt_claimed: boolean;
  __v: number;
  id: string;
}

export interface CertificateClaimTxns {
  compensationId: string;
  encodedOptinTxn: { [key: string]: number };
  encodedSendTxn: { [key: string]: number };
}
