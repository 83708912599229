import * as yup from 'yup';

import i18next from '@/i18n/config';

const DOCUMENTS_SUPPORTED_FORMATS = ['application/pdf'];
const IMAGES_SUPPORTED_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

const dateValidation = yup
  .date()
  .nullable()
  .transform((current, origin) => (origin === '' ? null : current));

const fileInputValidation = yup
  .array()
  .nullable()
  .test('format', i18next.t('validation.errors.format.document') as string, (value) => {
    if (!value) return true;

    const fileTypes = value.map((file) => file.type);
    const hasErrors = fileTypes
      .map((type) => DOCUMENTS_SUPPORTED_FORMATS.includes(type))
      .includes(false);

    return !hasErrors;
  });

const imageValidation = yup
  .array()
  .test('format', i18next.t('validation.errors.format.image') as string, (value) => {
    if (!value) return false;
    if (value.length === 0) return false;

    const fileTypes = value.map((file) => file.type);
    const hasErrors = fileTypes
      .map((type) => IMAGES_SUPPORTED_FORMATS.includes(type))
      .includes(false);

    return !hasErrors;
  });

export const documentUploadValidationSchema = yup.object({
  project_type: yup
    .object()
    .nullable()
    .required(i18next.t('validation.errors.required') as string),
  title: yup.string().required(i18next.t('validation.errors.required') as string),
  sdgs: yup.array().nullable(),
  description: yup.string().required(i18next.t('validation.errors.required') as string),
  country: yup
    .object()
    .nullable()
    .required(i18next.t('validation.errors.required') as string),
  project_url: yup.string().required(i18next.t('validation.errors.required') as string),
  project_latitude: yup.string().nullable(),
  project_longitude: yup.string().nullable(),
  thumbnail: imageValidation.required(i18next.t('validation.errors.required') as string),
  cover: imageValidation.required(i18next.t('validation.errors.required') as string),
  project_registration: dateValidation.required(i18next.t('validation.errors.required') as string),
  project_video: yup.string(),
  type: yup
    .object()
    .nullable()
    .required(i18next.t('validation.errors.required') as string),
  sub_type: yup
    .object()
    .nullable()
    .required(i18next.t('validation.errors.required') as string),
  methodology: yup.object().nullable(),
  credit_start: dateValidation.required(i18next.t('validation.errors.required') as string),
  credit_end: dateValidation.required(i18next.t('validation.errors.required') as string),
  validator: yup.object().nullable(),
  first_verifier: yup.object().nullable(),
  standard: yup
    .object()
    .nullable()
    .required(i18next.t('validation.errors.required') as string),
  registry: yup
    .object()
    .nullable()
    .required(i18next.t('validation.errors.required') as string),
  credits: yup.string().required(i18next.t('validation.errors.required') as string),
  serial_number: yup.string().required(i18next.t('validation.errors.required') as string),
  registry_url: yup.string().required(i18next.t('validation.errors.required') as string),
  pdd: fileInputValidation.min(1),
  validation_report: fileInputValidation,
  monitoring_report: fileInputValidation,
  verification_report: fileInputValidation.min(1),
  confirmation: yup.boolean().isTrue(),
});


export type UploadFormSchema = yup.InferType<typeof documentUploadValidationSchema>;
