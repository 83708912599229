import { SignedTxn } from 'algorand-session-wallet-deka';
import algosdk from 'algosdk';
import { Buffer } from 'buffer';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';

import { httpClient } from '@/lib/httpClient';
import { queryClient } from '@/lib/react-query';
import { sw } from '@/lib/sessionWallet';

import { Compensation, CompensationCalculation, compensationKeys } from '../types';

async function handleBurnClimatecoins({
  amount,
  encodedTransferTxn,
  encodedFundsTxn,
  encodedBurnTxn,
  signature,
  pickedNfts,
}: CompensationCalculation): Promise<Compensation> {
  // skip this in testing
  if (process.env.NODE_ENV === 'test') {
    return httpClient.post(`/compensations`, {
      signedTxn: [],
      amount,
      pickedNfts,
    });
  }

  // convert the txns to Transaction objects
  const transferTxn = algosdk.decodeUnsignedTransaction(
    Buffer.from(Object.values(encodedTransferTxn))
  );
  const fundsTxn = algosdk.decodeUnsignedTransaction(Buffer.from(Object.values(encodedFundsTxn)));
  const burnTxn = algosdk.decodeUnsignedTransaction(Buffer.from(Object.values(encodedBurnTxn)));

  const signedTxns: SignedTxn[] | undefined = await sw?.signTxn([transferTxn, fundsTxn, burnTxn]);
  if (!signedTxns) return Promise.reject('Transaction not signed');

  const signedTxnBlobs = signedTxns.map((stxn) => stxn.blob);

  return httpClient.post(`/compensations`, {
    signedTxn: signedTxnBlobs,
    signature,
    amount,
    pickedNfts,
  });
}

export function useBurnClimatecoins() {
  const alert = useAlert();
  return useMutation((burnParams: CompensationCalculation) => handleBurnClimatecoins(burnParams), {
    onSuccess: () => {
      queryClient.invalidateQueries(['account']);
      queryClient.invalidateQueries(compensationKeys.lists());
      alert.success('Climatecoins compensated succesfully');
    },
    onError: () => {
      alert.error('Error burning compensations');
    },
  });
}
