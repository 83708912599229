import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Spinner } from '@/componentes/Elements/Spinner/Spinner';
import { MainLayout } from '@/componentes/Layout/MainLayout';
import { useOtherContext } from '@/providers/Other.context';

import iconOds from '../../assets/icons/iconSvg.svg';
import { CarbonDocument } from '../documents';
import { useGetProjects } from './api/useGetProjects';

export const Projects = () => {
  const projectsResponse = useGetProjects();
  const { t } = useTranslation();
  const search = useLocation().search;
  const amount = new URLSearchParams(search).get('amount');
  const { setFifoProject } = useOtherContext();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  let numberOfProjects = 0;

  if (projectsResponse.data) {

    projectsResponse.data = projectsResponse.data.filter((project) => {
      const shouldBeDisplayed = project.nft && Number(project.nft.supply_remaining) > 0;
      if (shouldBeDisplayed) numberOfProjects += 1;
      return shouldBeDisplayed;
    });
    // Assign the defaultFifo project to the context for later been used by the compensationForm  
    const sortedCredits = projectsResponse.data.sort((a, b) => {
      return new Date(a.credit_start).getTime() - new Date(b.credit_start).getTime();
    });
    setFifoProject(sortedCredits[0].id)
  }
  if (projectsResponse.isLoading) {
    return (
      <MainLayout>
        <div className="mt-11 flex items-center justify-center p-4">
          <Spinner size="lg" />
        </div>
      </MainLayout>
    );
  } else {
    return (
      <MainLayout>
        <div className="mt-10">
          <p className="text-2xl font-medium">
            {numberOfProjects === 1
              ? `${numberOfProjects} ` + t('documents.Projects.explore.project.title')
              : `${numberOfProjects} ` + t('documents.Projects.explore.projects.title')}
          </p>
          <div className="flex w-full flex-row flex-wrap">
            {projectsResponse?.data?.map((project: CarbonDocument) => {
              if (project.nft) {
                return (
                  <div
                    key={project.id}
                    className="relative mt-8 mr-8 max-w-[325px] text-gray-900 antialiased"
                  >
                    <Link key={project.id} to={`project/${project.id}?amount=${amount || 0}`}>
                      <div
                        className="max-h-[312px] min-h-[312px] w-[288px] rounded-lg object-cover shadow-md"
                        style={{
                          backgroundImage: `linear-gradient(to bottom, transparent 0%, black 100%), url(${project.thumbnail.url})`,
                          backgroundPosition: 'center center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                        }}
                      />
                      <div className="absolute bottom-4 left-4 right-4 min-w-[250px] text-white">
                        <div className="flex items-center">
                          <span className="border-[rgba(255, 255, 255, 0.5)] mr-2 inline-block h-4 w-4 rounded-full border bg-primary-brightGreen"></span>
                          <p className="self-center overflow-hidden text-ellipsis whitespace-nowrap text-[13px] font-medium">
                            {project.project_type.name.toUpperCase()}
                          </p>
                        </div>
                        <h4 className="mt-4 truncate text-lg font-semibold">{project.title}</h4>
                        <div className="mt-4 flex items-center justify-between text-lg font-medium">
                          <div className="flex">
                            <p>
                              {project.nft.supply_remaining}{' '}
                              {t('documents.Projects.explore.supply')}
                            </p>
                          </div>
                          <div className="flex min-w-[56px] gap-2 rounded-full bg-[#777e8b] px-2 py-1">
                            <img src={iconOds} alt="iconOds" />
                            <span>{project.sdgs.length}</span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </MainLayout>
    );
  }
};
