type IconNextProps = {
  className?: string;
  onClick?: () => void;
};

const IconNext = ({ className, onClick }: IconNextProps) => {
  return (
    <button onClick={onClick} className={className}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#l700cxznha)">
          <circle cx="16" cy="16" r="16" fill="#FAFAFA" fillOpacity=".8" />
          <circle cx="16" cy="16" r="15.5" stroke="#000" strokeOpacity=".06" />
        </g>
        <path
          d="m14 21 5-5-5-5"
          stroke="#021120"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="l700cxznha"
            x="-27.183"
            y="-27.183"
            width="86.366"
            height="86.366"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="13.591" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_2695_6583"
            />
            <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_2695_6583" result="shape" />
          </filter>
        </defs>
      </svg>
    </button>
  );
};

export default IconNext;
