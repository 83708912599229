import './i18n/config';

import ReactGA from 'react-ga';

import { AppProvider } from '@/providers/AppProvider';

import { AppRouter } from './routes/AppRouter';

let TRACKING_ID = "";

if (process.env.REACT_APP_ENVIRONMENT === 'production')
  TRACKING_ID = "UA-257936650-3";
else
  TRACKING_ID = "UA-257936650-2";


ReactGA.initialize(TRACKING_ID);


function App() {
  return (
    <AppProvider>
      <AppRouter />
    </AppProvider>
  );
}

export default App;
