import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '@/i18n/en/translation.json';
import es from '@/i18n/es/translation.json';
import fr from '@/i18n/fr/translation.json';
import ko from '@/i18n/ko/translation.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  resources: {
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
    fr: {
      translation: fr,
    },
    ko: {
      translation: ko,
    },
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
