import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';

import { Button } from '@/componentes/Elements/Button/Button';
import { Spinner } from '@/componentes/Elements/Spinner/Spinner';
import { MainLayout } from '@/componentes/Layout/MainLayout';

import { useGetProject } from '../api/useGetProject';
import ContentView from './components/ContentView';
import ProjectCard from './components/ProjectCard';
import ProjectHeader from './components/ProjectHeader';

const ProjectDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const search = useLocation().search;
  const location = useLocation();

  const getIdFromUrl = () => {
    const pathname = location.pathname.split('/').reverse();
    const id = pathname[0];
    return id;
  };
  const project = useGetProject(getIdFromUrl());

  const amountFromUrlStr = new URLSearchParams(search).get('amount');
  const amountFromUrl = Number(amountFromUrlStr);

  if (project.data === undefined) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <MainLayout>
      <Button className="mt-5" onClick={() => navigate('/projects')}>
        {`<- ${t('projectDetail.back')}`}
      </Button>
      <ProjectHeader project={project.data} />
      <ContentView project={project.data}>
        <ProjectCard project={project.data} amountFromUrl={amountFromUrl} />
      </ContentView>
    </MainLayout>
  );
};

export default ProjectDetail;
