import { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Button } from '@/componentes/Elements/Button/Button';
import { MainLayout } from '@/componentes/Layout/MainLayout';
import { httpClient } from '@/lib/httpClient';
import { useWalletContext } from '@/providers/Wallet.context';
// import { useOtherContext } from '@/providers/Other.context';

// import { useGetProjects } from '../projects/api/useGetProjects';

export const Tokenize = () => {
  // const projectsResponse = useGetProjects();
  const { t } = useTranslation();
  const search = useLocation().search;
  const amount = new URLSearchParams(search).get('amount');
  // const { setFifoProject } = useOtherContext();
  const navigate = useNavigate();
  const { account, climatecoinBalance } = useWalletContext();

  const options1 = ["Solar Rooftops (TEST)", "Amazon Agroforestry (TEST)", "Sustainable Forests (TEST)", "Energy Efficiency in Low-Income Households (TEST)", "Biogas for Livestock Farms (TEST)", "Reforest Mix (TEST)", "Sustainable Land Use (TEST)", "Sustainable Land Management (TEST)",];
  const options2 = ["CDC_489_1_32_322_1_R2_XX_IN_1_1_2021", "CDC_488_1_11_322_14_R2_2B_BR_1_1_2022", "CDC_485_1_11_322_14_R1_3A_AU_1_1_2022", "CDC_484_1_11_323_14_R1_1A_US_1_1_2020", "CDC_483_1_11_322_14_R2_2C_KH_1_1_2020", "CDC_481_1_11_322_14_R3_2E_CO_1_1_2020", "CDC_479_3_11_323_14_R2_2B_CO_1_1_2020", "CDC_474_1_11_323_14_R1_2A_CO_1_1_2020",];
  const project_country = ["63e69f395d365f001c7626d0", "63e69f395d365f001c762689", "63e69f395d365f001c7626dd", "63e69f395d365f001c762697", "63e69f395d365f001c7626fa", "63e69f395d365f001c7626d1", "63e69f3a5d365f001c762718", "63e69f395d365f001c76269f",];
  const project_country_name = ["India", "Brazil", "Kenya", "China", "Mexico", "Indonesia", "Peru", "Costa Rica",];
  const project_longitude = ["72.8777", "-62.2159", "36.821946", "121.4737", "-100.3161", "106.8451", "-71.535", "-84.0907",];
  const project_latitude = ["19.0760", "-3.4653", "-1.292066", "31.2304", "25.6866", "-6.2146", "-16.3989", "9.9281",];
  const project_description = ["This project involves the installation of solar panels on rooftops of residential and commercial buildings in urban areas to reduce dependence on fossil fuel-based electricity - Vintage 2021",
    "This project aims to reduce emissions from deforestation and forest degradation in the Amazon region by promoting sustainable agroforestry practices among smallholder farmers. - Vintage 2022",
    "This project involves the protection and restoration of degraded forests and the promotion of sustainable livelihoods among local communities through the sale of sustainably harvested timber and non-timber forest products. - Vintage 2022",
    "This project involves the installation of energy-efficient lighting and appliances in low-income households in urban areas to reduce energy consumption and carbon emissions. - Vintage 2020",
    "This project involves the installation of biogas digesters on small-scale livestock farms to capture methane emissions from animal waste and convert it into clean energy for cooking and heating. - Vintage 2020",
    "This project involves the reforestation of degraded land with a mix of native tree species and agroforestry crops, providing sustainable livelihoods for local communities and sequestering carbon in the process. - Vintage 2020",
    "This project involves the promotion of sustainable land use practices and the restoration of degraded forests, providing sustainable livelihoods for local communities and sequestering carbon in the process. - Vintage 2020",
    "This project involves the implementation of sustainable land management practices on small-scale farms, including the use of cover crops and reduced tillage, sequestering carbon in the soil and reducing greenhouse gas emissions. - Vintage 2020",
  ];
  const project_jpg_url = ['https://thumbnails-photos.amazon.com/v1/thumbnail/YrobUj74QUy2TEBvllAs3g?viewBox=540%2C358&ownerId=AGNAMQHTQ63SK&groupShareToken=ZR5mAeWOTOOH97zFxlBjkg.bqsKw1SIKqxCF6FUmADulH',
    "https://thumbnails-photos.amazon.com/v1/thumbnail/qpnuqRNbQvSZeeTGaOjSHw?viewBox=1080%2C726&ownerId=AGNAMQHTQ63SK&groupShareToken=nDglIZC2SCipaFVVUfOWMQ.5EYgSD5eiOteniRiNXsuIp",
    "https://thumbnails-photos.amazon.com/v1/thumbnail/hfchi1K3SlSxoU0XnAdaog?ownerId=AGNAMQHTQ63SK&viewBox=960%2C540&groupShareToken=iTEABAjOTIiN1vmlfhgBOQ._Hnm-PMEw9WnWjcPNyI7cv",
    "https://thumbnails-photos.amazon.com/v1/thumbnail/1vKiC0TcRmOgIlOQ8rFUzA?viewBox=1080%2C810&ownerId=AGNAMQHTQ63SK&groupShareToken=d2S407s8QJG9xq4j9qntWw.VWbGtZTxk6_-efFxUXoHYr",
    "https://thumbnails-photos.amazon.com/v1/thumbnail/B9-ikzj8QjWdkS8tqbKxkQ?viewBox=319%2C288&ownerId=AGNAMQHTQ63SK&groupShareToken=VNL0JzSOQ3C2Yeu5sZNZpA.B0kAeciWJEtYrl35KDKgoo",
    "https://thumbnails-photos.amazon.com/v1/thumbnail/AEbnQh5MTu6bnbWQowjrIQ?viewBox=1080%2C720&ownerId=AGNAMQHTQ63SK&groupShareToken=koMj6KiLQzq2HACULPT7Lg.LM2pHBDms4LYRWF1PRPtGU",
    "https://thumbnails-photos.amazon.com/v1/thumbnail/0CgT75DtTIeeXoSYWI9NIw?ownerId=AGNAMQHTQ63SK&viewBox=959%2C540&groupShareToken=4zrbrV0KST-eJpG-89cegQ.p_gvVzlcOZQq7JKBb5-Rf8",
    "https://thumbnails-photos.amazon.com/v1/thumbnail/DcP3EfXvQmey54WELmlGpw?viewBox=1058%2C547&ownerId=AGNAMQHTQ63SK&groupShareToken=LTn2IawIQiWIX2Ucq0_SCA.nL66XuptHnOX-Zs_430aN6",
  ];
  // if (process.env.REACT_APP_ENVIRONMENT === 'local')
  //   options2 = ["CDC_489_1_32_322_1_R2_XX_IN_1_1_2021", "Option B", "Option C"];
  // if (process.env.REACT_APP_ENVIRONMENT === 'develop')
  //   options2 = ["CDC_489_1_32_322_1_R2_XX_IN_1_1_2021", "Option B", "Option C"];

  const [selectedOption1, setSelectedOption1] = useState("");
  const selectedOption2 = options2[options1.indexOf(selectedOption1)];
  const thumbnail = project_jpg_url[options1.indexOf(selectedOption1)];
  const description = project_description[options1.indexOf(selectedOption1)];
  const country = project_country[options1.indexOf(selectedOption1)];
  const countryName = project_country_name[options1.indexOf(selectedOption1)];
  const longitude = project_longitude[options1.indexOf(selectedOption1)];
  const latitude = project_latitude[options1.indexOf(selectedOption1)];
  const [showcredits, setShowcredits] = useState(false);
  const [showtokenizing, setShowtokenizing] = useState(false);
  const [tokenizationstart, setTokenizationstart] = useState(true);
  const [credits, setCredits] = useState(10);
  const inputRef = useRef<HTMLInputElement>(null);
  const [generatedProject, setGeneratedProject] = useState("");



  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  let resp = "";


  const handleOption1Change = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption1(e.target.value);
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (e.target.value !== '')
      setShowcredits(true)
    else
      setShowcredits(false)
  };

  const handleCredits = (e: ChangeEvent<HTMLInputElement>) => {
    setCredits(Number(e.target.value));

  };

  // const handleOption2Change = (e: ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedOption2(e.target.value);
  // };

  const tokenize = async () => {

    if (credits > 0 && credits < 201) {
      console.log('tokenize!')
      setShowtokenizing(true);


      if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        resp = await httpClient.post(`/ecoregistry/tokenize`, {

          "registry_id": "5",
          "sdgs": [
            "63ed623539559e19184b26de", "63ed623539559e19184b26e4"
          ],
          "registry_url": "https://www.ecoregistry.io/projects/333",
          "serial_number": selectedOption2,
          "credits": credits,
          "credit_end": "2024-12-31",
          "credit_start": "2020-03-27",
          "project_video": "https://www.youtube.com/watch?v=uF8Ix5NSYzU",
          "project_registration": "2000-01-01",
          "project_longitude": "-71.535",
          "project_latitude": "-16.3989",
          "project_url": "https://www.climatecoin.io/",
          "thumbnail": thumbnail,
          "description": description,
          "title": selectedOption1,
          "country": "63ed623639559e19184b28f6",
          "project_type": "63ed623539559e19184b26d5",
          "registry": "63ed623439559e19184b26be",
          "standard": "63ed623639559e19184b28e5",
          "sub_type": "63ed623539559e19184b270d",
          "type": "63ed623539559e19184b26ea",
          "wallet": account?.address

          // signedTxn: signedTxnBlobs,
          // signature,
          // amount,
          // pickedNfts,
        });
        // console.log('resp');
        // console.log(resp);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // console.log(resp.id);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setGeneratedProject(resp.id)
      }

      if (process.env.REACT_APP_ENVIRONMENT === 'develop') {
        console.log('credits' + credits)
        resp = await httpClient.post(`/ecoregistry/tokenize`, {

          "registry_id": "5",
          "sdgs": [
            "63971fdd6083b5001c974b60"
          ],
          "registry_url": "https://www.ecoregistry.io/projects/333",
          "serial_number": selectedOption2,
          "credits": credits,
          "credit_end": "2024-12-31",
          "credit_start": "2020-03-27",
          "project_video": "https://www.youtube.com/watch?v=uF8Ix5NSYzU",
          "project_registration": "2000-01-01",
          "project_longitude": "-71.535",
          "project_latitude": "-16.3989",
          "project_url": "https://www.climatecoin.io/",
          "thumbnail": thumbnail,
          "description": description,
          "title": selectedOption1,
          "country": "63971fde6083b5001c974d87",
          "project_type": "63971fdc6083b5001c974b3c",
          "registry": "63971fdb6083b5001c974ad6",
          "standard": "63971fde6083b5001c974d61",
          "sub_type": "63971fdd6083b5001c974b84",
          "type": "63971fdd6083b5001c974b65",
          "wallet": account?.address

          // signedTxn: signedTxnBlobs,
          // signature,
          // amount,
          // pickedNfts,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setGeneratedProject(resp.id)

      }

      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        console.log('credits' + credits)
        resp = await httpClient.post(`/ecoregistry/tokenize`, {

          "registry_id": "5",
          "sdgs": [
            "63e69f385d365f001c762462"
          ],
          "registry_url": "https://www.ecoregistry.io/projects/333",
          "serial_number": selectedOption2,
          "credits": credits,
          "credit_end": "2024-12-31",
          "credit_start": "2020-03-27",
          "project_video": "https://www.youtube.com/watch?v=uF8Ix5NSYzU",
          "project_registration": "2000-01-01",
          "project_longitude": longitude,
          "project_latitude": latitude,
          "project_url": "https://www.climatecoin.io/",
          "thumbnail": thumbnail,
          "description": description,
          "title": selectedOption1,
          "country": country,
          "project_type": "63e69f385d365f001c762453",
          "registry": "63e69f385d365f001c762441",
          "standard": "63e69f395d365f001c762663",
          "sub_type": "63e69f385d365f001c762486",
          "type": "63e69f385d365f001c762467",
          "wallet": account?.address

          // signedTxn: signedTxnBlobs,
          // signature,
          // amount,
          // pickedNfts,
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setGeneratedProject(resp.id)
      }



      setTokenizationstart(false);
    }

    // console.log(resp);
    // navigate('/projects')
  }

  return (
    <MainLayout>
      <div className="flex-col mt-5 ">

        <div className="flex  justify-center">
          <img src="/Ecoregistry.png" className="h-10 w-25" />
        </div>
        <div className="flex   justify-center">
          <p id="welcome" className="mt-2 text-xl text-black-500 dark:text-black-400"> Welcome to the connection interface between EcoRegistry and Climatecoin</p>
        </div>
        <br />
        <div className="flex justify-center bg-green-600 rounded-lg p-2">
          <p id="welcome" className="mt-2 text-base  text-white">For this beta testing purposes you are now connected to a fictitious Ecoregistry project developer account where you can see your available credits and tokenize them as Climatecoins by freezing them in the offchain registry. Every user participating in the beta testing is connected to this testing account. In production real project developers will be able to log into their Ecoregistry accounts and see their available projects, amount of credits, and serial numbers available for them to tokenize them as Climatecoins</p>
        </div>
        {/* <div className="flex   justify-center">
          <p id="welcome" className="mt-2 text-base text-black-500 dark:text-black-400"> For this beta testing purposes you are now connected to a fictitious Ecoregistry project developer account where you can see your available credits and tokenize them as Climatecoins by freezing them in the offchain registry. Every user participating in the beta testing is connected to this testing account. In production real project developers will be able to log into their Ecoregistry accounts and see their available projects, amount of credits, and serial numbers available for them to tokenize them as Climatecoins</p>
        </div> */}


        <div className="flex mt-5 space-x-20">
          <div className="flex-col w-1/2">
            <label htmlFor="project" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Project</label>
            <select
              id="project"
              value={selectedOption1}
              onChange={handleOption1Change}
              className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-200 dark:border-gray-400 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Choose a Project</option>
              {options1.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>


            {/* <p id="helper-text-explanation" className="mt-2 text-base text-gray-500 dark:text-gray-400">For this beta all the wallets has the same projects.</p> */}
          </div>

          {
            showcredits &&
            <div className="flex-col w-1/2">
              <label htmlFor="serial" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Serial</label>
              <input
                type="text"
                id="disabled-input-2"
                aria-label="disabled input 2"
                className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2 w-96 cursor-not-allowed dark:bg-gray-200 dark:border-gray-400 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={selectedOption1 === "" ? "" : selectedOption2}
                disabled
                readOnly
              />

              {/* <p id="helper-text-explanation" className="mt-2 text-base text-gray-500 dark:text-gray-400">For this beta all the projects has the same serials.</p> */}
            </div>
          }

        </div>


      </div >

      {showtokenizing &&
        <div id="1" className="flex  absolute" style={{ height: "165px", marginLeft: "350px" }}>
          <div id="spinner" className="flex" style={{ marginRight: "100px" }}>

            {tokenizationstart ?
              <div role="status" className="bg-green-500 rounded-lg  " style={{ width: "400px" }}>
                <div style={{ marginTop: "40px", marginLeft: "175px" }}>
                  <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                </div>
                <div style={{ marginLeft: "155px" }}>
                  <span className="text-black-200 dark:text-black-400">Tokenizing...</span>
                </div>
                <div style={{ marginLeft: "80px" }}>
                  <br />
                  <span className="text-black-200 dark:text-black-400">This process will take a few seconds.</span>
                </div>
              </div>
              :
              <div role="status" className="bg-green-500  rounded-lg  " style={{ width: "400px" }}>
                <div style={{ marginTop: "40px", marginLeft: "55px" }}>
                  <span className="text-black-200 dark:text-black-400 text-lg">Your tokenization was successfully! 🎉</span>
                </div>
                <div style={{ marginLeft: "70px" }}>
                  <br />
                  <Button onClick={() => navigate(`/projects/project/${generatedProject}?amount=0`)} className="w-64 h-12" >
                    {/* <Button onClick={() => navigate('/projects')} className="w-64 h-12" > */}

                    Click to see your project
                  </Button>
                </div>
              </div>

            }

          </div>
        </div>
      }


      {
        showcredits &&

        <div className="flex-col mt-5 w-full">

          <div id="2" className="flex  space-x-20 ">
            <div className="flex-col w-1/2">
              <label htmlFor="small-input" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Description</label>

              <textarea

                id="disabled-input-2"
                aria-label="disabled input 2"
                className="bg-white border border-gray-400 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-6 w-full cursor-not-allowed dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={description}
                disabled
                readOnly
                style={{ marginTop: '0', paddingTop: '0' }}
              />

              <div>
                {/* <p id="helper-text-explanation" className="mt-2 text-base text-gray-500 dark:text-gray-400">For this beta we allow 200 credits max. for each project tokenization.</p> */}
              </div>
            </div>

            <div className="flex-col w-1/2">
              <label htmlFor="serial" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Country</label>
              <input
                type="text"
                id="disabled-input-2"
                aria-label="disabled input 2"
                className="bg-white border border-gray-400 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 cursor-not-allowed dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={countryName}
                disabled
                readOnly
              />

              <div>

              </div>
            </div>


          </div>

          <div id="3" className="flex mt-5 space-x-20">
            <div className="flex-col w-1/2" >
              <div className="flex-row w-1/2" >
                <label htmlFor="serial" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Destination Wallet</label>

                <input
                  type="text"
                  id="disabled-input-2"
                  aria-label="disabled input 2"
                  className="bg-white border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 cursor-not-allowed dark:bg-gray-200 dark:border-gray-400 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={account?.address}
                  style={{ width: '605px' }}
                  disabled
                  readOnly
                />

                <div style={{ width: '400px' }}>
                  <p id="helper-text-explanation" className="mt-2 text-base text-gray-500 dark:text-gray-400">You will receive the climatecoins minus the 5% tokenization fee in this wallet.</p>
                </div>
              </div>

              <div className="flex-row w-1/2">

                {/* <p id="helper-text-explanation" className="mt-2 text-base text-gray-500 dark:text-gray-400">You will receive the climatecoins in this wallet.</p> */}
                <div id="2" className="flex mt-5 space-x-20" style={{ width: '605px' }} >
                  <div className="flex-col w-2/3">
                    <label htmlFor="small-input2" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Credits to tokenize</label>

                    {/* <input
                      type="text"
                      id="small-input2"
                      autoFocus
                      ref={inputRef}
                      onChange={handleCredits}
                      value={credits}
                      className="block p-2.5 text-gray-900 border border-gray-400 rounded-lg bg-white text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    /> */}
                    <input
                      type="number"
                      id="small-input2"
                      autoFocus
                      ref={inputRef}
                      onChange={handleCredits}
                      value={credits}
                      min="1"
                      max="200"
                      className="block p-2.5 text-gray-900 border border-gray-400 rounded-lg bg-white text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      style={{ width: "90px" }}
                    />
                    {credits < 1 || credits > 200 ? <p className="text-red-500">Please enter a number between 1 and 200.</p> : null}


                    <div >
                      <p id="helper-text-explanation" className="mt-2 text-base text-gray-500 dark:text-gray-400">For this beta we allow 200 credits max. for each project tokenization.</p>
                    </div>
                  </div>

                  <div className="flex-col w-1/3">
                    <label htmlFor="serial" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Availalble credits</label>
                    <input
                      type="text"
                      id="disabled-input-2"
                      aria-label="disabled input 2"
                      className="bg-white border border-gray-400 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 cursor-not-allowed dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value="200"
                      disabled
                      readOnly
                    />

                    <div>

                    </div>
                  </div>
                </div>

              </div>


            </div>

            <div className="flex-col w-1/2">
              <label htmlFor="serial" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Thunmbail</label>
              <div
                // className="max-h-[312px] min-h-[312px] w-[288px] rounded-lg object-cover shadow-md"
                className="max-h-[208px] min-h-[208px] w-[192px] rounded-lg object-cover shadow-md"
                style={{
                  backgroundImage: `linear-gradient(to bottom, transparent 0%, black 100%), url(${thumbnail})`,
                  backgroundPosition: 'center center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />

              <div>
                {/* <p id="helper-text-explanation" className="mt-2 text-base text-gray-500 dark:text-gray-400">You will receive the climatecoins in this wallet.</p> */}
              </div>
            </div>
          </div>

          {/* <div id="2" className="flex mt-5 space-x-20 ">
            <div className="flex-col w-1/2">
              <label htmlFor="small-input" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Credits to tokenize</label>

              <input
                type="text"
                id="small-input"
                autoFocus
                onChange={handleCredits}
                value={credits}
                className="block p-2.5 text-gray-900 border border-gray-400 rounded-lg bg-white text-base focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />

              <div>
                <p id="helper-text-explanation" className="mt-2 text-base text-gray-500 dark:text-gray-400">For this beta we allow 200 credits max. for each project tokenization.</p>
              </div>
            </div>

            <div className="flex-col w-1/2">
              <label htmlFor="serial" className="block mb-2 text-base font-medium text-gray-900 dark:text-grey">Avaialble credits</label>
              <input
                type="text"
                id="disabled-input-2"
                aria-label="disabled input 2"
                className="bg-white border border-gray-400 text-gray-900 text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 cursor-not-allowed dark:bg-gray-200 dark:border-gray-300 dark:placeholder-gray-500 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value="200"
                disabled
                readOnly
              />

              <div>

              </div>
            </div>

          </div> */}




          <div id="4" className="flex mt-10 justify-center">
            <div className="flex-col">
              {showtokenizing ?
                <Button onClick={tokenize} className="w-48 h-12" disabled>
                  Tokenize
                </Button>
                :
                <Button onClick={tokenize} className="w-48 h-12" >
                  Tokenize
                </Button>
              }
            </div>

          </div>

        </div>
      }


    </MainLayout >
  );
  // }
};

