import { useState } from 'react';

import IconNext from '@/componentes/Icon/IconNext';

type ProjectGalleryProps = {
  imagesSrc: string[];
};

const ProjectGallery = ({ imagesSrc }: ProjectGalleryProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextImg = () => {
    if (currentIndex === 0) {
      setCurrentIndex(currentIndex + 1);
    } else setCurrentIndex(currentIndex - 1);
  };

  return (
    <div className="relative">
      <div
        className="text-base relative mt-10 flex h-[400px] max-w-[650px] justify-evenly gap-4 rounded-2xl border px-16 py-2 "
        style={{
          backgroundImage: ` url(${imagesSrc[currentIndex]})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <IconNext onClick={handleNextImg} className="absolute top-1/2 right-8 cursor-pointer" />
    </div>
  );
};

export default ProjectGallery;
