import { useTranslation } from 'react-i18next';

import IconArrowDown from '@/componentes/Icon/IconArrowDown';
import { CarbonDocument } from '@/features/documents';

import ProjectCertification from './ProjectCertification';
import ProjectGallery from './ProjectGallery';
import ProjectInfo from './ProjectInfo';
import ProjectSdg from './ProjectSdg';

type ContentViewProps = {
  children: React.ReactNode;
  project: CarbonDocument;
};

export default function ContentView({ children, project }: ContentViewProps) {
  const { t } = useTranslation();
  return (
    <div className="gap-4 md:grid md:grid-cols-3">
      <div className="md:left md:col-span-2 md:flex md:justify-center">
        <div className="text-neutral-2 md:w-[670px]">
          <div className="mt-10 mb-4 flex items-center justify-between">
            <h4 className="text-2xl font-semibold">{t('projectDetail.projectGoals')}</h4>
            <IconArrowDown />
          </div>
          <hr />
          <div className="py-4">
            <ProjectSdg project={project} />
          </div>
          <ProjectInfo project={project} />
          <div>
            <div className="mt-10 mb-4 flex items-center justify-between">
              <h4 className="text-2xl font-semibold">{t('projectDetail.gallery')}</h4>
              <IconArrowDown />
            </div>
            <hr />
            <div className="pt-5">
              <ProjectGallery imagesSrc={[project?.thumbnail?.url, project?.cover?.url]} />
            </div>
          </div>
          <div className="mt-10 mb-4 flex justify-between">
            <h4 className="text-2xl font-semibold">{t('projectDetail.description')}</h4>
            <IconArrowDown />
          </div>
          <hr />
          <div className="pt-5">
            <p>{project.description}</p>
          </div>
          <div className="pt-5">
            <ProjectCertification project={project} />
          </div>
        </div>
      </div>
      <div className="relative hidden md:right-4 md:col-span-1 md:block">{children}</div>
    </div>
  );
}
