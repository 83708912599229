import { CarbonDocument } from '@/features/documents';

type ProjectHeaderProps = {
  project: CarbonDocument;
};

const ProjectHeader = ({ project }: ProjectHeaderProps) => {
  return (
    <div
      className="text-base relative mt-10 flex h-[263px] max-w-[1280px] justify-evenly gap-4 rounded-2xl border px-16 py-2 text-white"
      style={{
        backgroundImage: `linear-gradient(to left top, rgba(255,255,255,0.2) 20%,rgba(54, 66, 55,0.7) 40%), url(${project.thumbnail?.url})`,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="absolute bottom-10 left-8">
        <h1 className="text-[40px] font-black">{project.title}</h1>
        <div className="flex">
          <span className="border-[rgba(255, 255, 255, 0.5)] mr-1 inline-block h-4 w-4 rounded-full border bg-primary-brightGreen"></span>
          <h1 className="text-[13px] font-medium">{project?.type?.name}</h1>
        </div>
      </div>
    </div>
  );
};

export default ProjectHeader;
